<template>
  <div class="app-calendar overflow-hidden border">
    <b-row>
      <b-col>
        <b-card class="header-marcar_consulta bg-gradient-primary ">
          <b-row align-v="center">
            <b-col>
              <h1>
                <feather-icon icon="CalendarIcon" size="30" class="mr-50" />
                Agenda
              </h1>
              <!-- <h4 class="mt-1">Selecione um paciente:</h4> -->
            </b-col>
          </b-row>
        </b-card>
      </b-col>
    </b-row>

    <div class="row no-gutters">
      <!-- Sidebar -->
      <div
        class="
          col
          app-calendar-sidebar
          flex-grow-0
          overflow-hidden
          d-flex
          flex-column
        "
        :class="{ show: isCalendarOverlaySidebarActive }"
      >
        <calendar-sidebar
          :is-event-handler-sidebar-active.sync="isEventHandlerSidebarActive"
        />
      </div>

      <!-- Calendar -->
      <div class="col position-relative">
        <div class="card shadow-none border-0 mb-0 rounded-0">
          <div class="card-body pb-0">
            <full-calendar
              ref="refCalendar"
              :options="calendarOptions"
              class="full-calendar"
            >
              <template v-slot:eventContent="arg">
                <div
                  :class="'col-12 text-white bg-' + arg.backgroundColor"
                  :title="arg.timeText + ' ' + arg.event.title"
                >
                  <strong>{{ arg.timeText }}</strong>
                  <b-dropdown  dropright class="pb-0" variant="link" no-caret style="width: 100%; position: absolute; left:0;">
                    <b-dropdown-item :to="'consulta/' + arg.event.id">
                        <feather-icon icon="ClipboardIcon" />Ir para
                        Consulta
                    </b-dropdown-item>
                  </b-dropdown>
                </div>
              </template>
            </full-calendar>
          </div>
        </div>
      </div>

      <!-- Sidebar Overlay -->
      <div
        class="body-content-overlay"
        :class="{ show: isCalendarOverlaySidebarActive }"
        @click="isCalendarOverlaySidebarActive = false"
      />
      <calendar-event-handler
        v-model="isEventHandlerSidebarActive"
        :event="event"
        :clear-event-data="clearEventData"
        @remove-event="removeEvent"
        @add-event="addEvent"
        @update-event="updateEvent"
      />
    </div>
  </div>
</template>

<script>
import { BCard, BCol, BRow, BDropdown, BDropdownItem } from 'bootstrap-vue'
import FullCalendar from '@fullcalendar/vue'
import store from '@/store'
import { onUnmounted } from '@vue/composition-api'
import calendarStoreModule from './agendaStoreModule'
import CalendarSidebar from './calendar-sidebar/CalendarSidebar.vue'
import CalendarEventHandler from './calendar-event-handler/CalendarEventHandler.vue'
import useCalendar from './useAgenda'

export default {
  components: {
    FullCalendar, // make the <FullCalendar> tag available
    CalendarSidebar,
    CalendarEventHandler,
    BDropdown,
    BDropdownItem,
    BCard,
    BCol,
    BRow,
  },
  methods: {},
  setup() {
    const CALENDAR_APP_STORE_MODULE_NAME = 'calendar'

    // Register module
    if (!store.hasModule(CALENDAR_APP_STORE_MODULE_NAME))
      store.registerModule(CALENDAR_APP_STORE_MODULE_NAME, calendarStoreModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(CALENDAR_APP_STORE_MODULE_NAME))
        store.unregisterModule(CALENDAR_APP_STORE_MODULE_NAME)
    })

    const {
      refCalendar,
      isCalendarOverlaySidebarActive,
      event,
      clearEventData,
      addEvent,
      fetchEvents,
      updateEvent,
      removeEvent,
      refetchEvents,
      calendarOptions,

      // ----- UI ----- //
      isEventHandlerSidebarActive,
    } = useCalendar()

    fetchEvents()

    return {
      refCalendar,
      isCalendarOverlaySidebarActive,
      event,
      clearEventData,
      addEvent,
      updateEvent,
      removeEvent,
      refetchEvents,
      calendarOptions,

      // ----- UI ----- //
      isEventHandlerSidebarActive,
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/apps/calendar.scss';
</style>
