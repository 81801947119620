import axiosIns from '@/libs/axios'
import medicalAppointmentService from '@/services/medicalAppointmentService'
import { hideSpinner, showSpinner } from '@/services/spinService'
import axios from '@axios'
import { calendarUtils } from './calendar-options'

export default {
  namespaced: true,
  state: {
    calendarOptions: calendarUtils.getOptions(),
    selectedCalendars: calendarUtils.getOptions().map(option => option.label),
  },
  getters: {},
  mutations: {
    SET_SELECTED_EVENTS(state, val) {
      state.selectedCalendars = val
    },
  },
  actions: {
    fetchEvents(ctx, { calendars }) {
      return new Promise((resolve, reject) => {
        showSpinner()
        const queryParams =
          '?' + calendars.map(type => `consultationTypes=${type}`).join('&')
        medicalAppointmentService
          .getSchedule(queryParams)
          .then(response => {
            const calendarResponse =
              response.content.map(item => ({
                title: `${item.patient.physicalPerson.firstName} ${item.patient.physicalPerson.lastName}`,
                start: item.startDate,
                end: item.endDate,
                allDay: false,
                url: '',
                color: calendarUtils.getById(item.consultationTypes[0].id)
                  .color,
                id: item.id,
              })) || []
            resolve(calendarResponse)
          })
          .catch(error => reject(error))
          .finally(() => hideSpinner())
      })
    },
    addEvent(ctx, { event }) {
      return new Promise((resolve, reject) => {
        axios
          .post('/apps/calendar/events', { event })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    updateEvent(ctx, { event }) {
      return new Promise((resolve, reject) => {
        axios
          .post(`/apps/calendar/events/${event.id}`, { event })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    removeEvent(ctx, { id }) {
      return new Promise((resolve, reject) => {
        axios
          .delete(`/apps/calendar/events/${id}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
  },
}
